import type {Ref} from "vue";
import {ref} from 'vue'
import Timer from "@/utils/timer";
import type {NotificationInterface} from "@/services/notification-interface";
import type {notificationOptions} from "@/types/notification";

const
  store: Ref<NotificationInterface[]> = ref([]),
  globalConfig = {
    maxNotifications: 5
  };


class Notification implements NotificationInterface {
  title: string;
  options: notificationOptions;
  private readonly timer: Timer | null = null;
  promise: Promise<void>;
  #defaultOptions: notificationOptions = {
    type: 'info',
    timeout: 5000,
    message: ref(null),
    progress: ref(null)
  }

  constructor(title: string, options: null | notificationOptions = null) {

    this.title = title
    this.options = {...this.#defaultOptions, ...(options ?? {})}

    if (this.options.timeout instanceof Promise) {
      this.promise = this.options.timeout
    } else {
      let resolver: (value: void) => void = () => {
      };
      this.promise = new Promise((resolve) => {
        resolver = resolve
      });
      this.timer = new Timer(this.options.timeout, resolver)
    }
    this.promise.then(() => {
      console.debug('remove notification', this.title)
      store.value.splice(store.value.indexOf(this), 1)
    })
  }

  get hasTimer(): boolean {
    return this.timer !== null
  }

  get type(): string {
    return this.options.type
  }

  get message(): Ref<string | null> {
    return this.options.message
  }

  get progress(): Ref<number | null> {
    return this.options.progress
  }

  kill(): void {
    if (this.timer === null) {
      console.error('you set a promise as timeout, you have to resolve it manually')
      return
    }
    store.value.splice(store.value.indexOf(this), 1)
  }

  pause(): void {
    if (this.timer === null) {
      console.error('you set a promise as timeout, you have to resolve it manually')
      return
    }
    this.timer.pause()
  }

  resume(): void {
    if (this.timer === null) {
      console.error('you set a promise as timeout, you have to resolve it manually')
      return
    }
    this.timer.resume()
  }
}

function checkMax() {
  let count = 1
  while (count <= store.value.length && globalConfig.maxNotifications < store.value.length) {
    count++
    const oldestNotification = store.value.slice().reverse().find(n => n.hasTimer)
    store.value.find(n => n === oldestNotification)?.kill()
  }
}

const notifications = {
  setMax: (max: number) => {
    globalConfig.maxNotifications = max
    checkMax()
  },
  add: (title: string, options: notificationOptions | null) => {
    const n = new Notification(title, options)
    store.value.unshift(n)
    checkMax()
    return n
  },
  getAll: () => store.value,
  closeAll: () => {
    store.value.splice(0).map(n => n.kill())
  }
}

export default notifications
