import type { Order } from "@/models/Order";
import { ORDER_STATUS } from "@/models/Order";
import { AbstractRepository } from "@/repositories/abstract-repository";

export default class OrderRepository extends AbstractRepository<Order> {
  static readonly databaseName = 'orders';

  constructor() {
    super(OrderRepository.databaseName);
  }

  public async generateId(): Promise<string> {
    const info = await this.database.info()
    return (info.doc_count + 1).toString();
  }

  public async findAllWithDescendingOrder(): Promise<Order[]> {
    const orders = await this.findAll();

    return orders.sort((a, b) => {
      const idA = parseInt(a._id, 10);
      const idB = parseInt(b._id, 10);

      return idB - idA;
    });
  }

  public async findAllPendingOrders(): Promise<Order[]> {
    return this.database.find({
      selector: {
        status: {
          $eq: ORDER_STATUS.PENDING
        }
      }
    }).then((result) => result.docs)
      .catch((error) => {
        console.error('Error finding all ' + this.database.name + ':', error);
        return Promise.resolve([]);
      });
  }
}
