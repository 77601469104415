import TranslationRepository from "@/repositories/translation-repository";
import progressStore from "@/stores/progress";
import type { ImportInterface } from "@/services/import/import-interface";
import HttpClientSingleton from "@/services/factory/http-client-singleton";
import type { ResponseData } from "@/services/api/resources/ResponseData";
import type { Translation } from "@/models/Translation";

export class TranslationImport implements ImportInterface {
  #repository: TranslationRepository;

  constructor(repository: TranslationRepository | null = null) {
    this.#repository = repository ?? new TranslationRepository();
  }

  getProgress() {
    return progressStore.getProgress(this.constructor.name)
  }

  async import(): Promise<any> {
    const progress = progressStore.initProgress(this.constructor.name)
    const repositoryCreate = this.#repository.recreate();
    const repositoryPromises: Promise<any>[] = [];

    repositoryPromises.push(repositoryCreate);

    return HttpClientSingleton.getInstance().getAll('/api/PwaTranslation', {}, (response: ResponseData<Translation[]>) => {
      const translations: Translation[] = [];

      response.data.map((data: any) => {
        data.trans.map((translationData: any) => {
          const translation = {} as Translation;
          translation._id = data.productNumber + '_' + translationData.locale;
          translation.productNumber = data.productNumber;
          translation.name = translationData.name;
          translation.language = translationData.locale;
          translation.searchField = translationData.name.toLowerCase();
          translation.syncDate = new Date();

          translations.push(translation);
        });
      });

      progress.updateTotal(response.total).addProgress(response.data.length);
      repositoryCreate.then(() => repositoryPromises.push(this.#repository.bulkDocs(translations)));
    }, 1000).finally(() => {
      this.createIndexAfterAllTasksFinished(repositoryPromises);
      progress.finish();
    });
  }

  createIndexAfterAllTasksFinished(repositoryPromises: Promise<any>[] = []) {
    Promise.all(repositoryPromises).finally(() => this.#repository.createIndex());
  }
}
