import type { Customer } from "@/models/Customer";
import { AbstractRepository } from "@/repositories/abstract-repository";

export default class CustomerRepository extends AbstractRepository<Customer> {
  static readonly databaseName = 'customers';

  constructor() {
    super(CustomerRepository.databaseName);
  }

  async createIndex() {
    await this.database.createIndex({
      index: {
        fields: [
          'searchField',
        ]
      }
    })
  }

  async search(searchTerm: string): Promise<Customer[]> {
    try {
      if (searchTerm === '') {
        return await this.findAll();
      }

      const result = await this.database.find({
        selector: {
          searchField: {
              $regex: searchTerm.toLowerCase(),
          }
        }
      });

      return result.docs;
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve([]);
    }
  }

  async findByIds(ids: string[]): Promise<Customer[]> {
    try {
      const result = await this.database.allDocs({include_docs: true, keys: ids});

      return result.rows
        .map(row => "doc" in row ? row.doc as Customer : null)
        .filter(doc => doc !== null) as Customer[]
        ;
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve([]);
    }
  }
}
