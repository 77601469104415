<script lang="ts" setup>
import useUserStore from "@/stores/user";

function onChange(event: Event) {
  const store = useUserStore();
  const target = event.target as HTMLSelectElement;

  store.locale = target.value;
}
</script>

<template>
  <div class="min-w-[100px]" data-qa="language-chooser">
    <div :class="`language-flag locale-${$i18n.locale}`"></div>
    <select v-model="$i18n.locale" class="bg-transparent text-white" @change="onChange">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale" class="bg-grey">
        {{ $t(`language.${locale}`) }}
      </option>
    </select>
  </div>
</template>

<style scoped>
.language-flag {
  display: inline-block;
  border-radius: 0.375rem;
  width: 0.875rem;
  height: 0.75rem;
}

.locale-de-DE {
  background-image: linear-gradient(to bottom, #000000 33%, #dd0000 33%, #dd0000 66%, #ffce00 66%);
}

.locale-en-GB {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width='640' height='480'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M-85 0h682v512H-85z'%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path='url%28%23a%29' transform='translate%2880%29 scale%28.94%29'%3E%3Cpath fill='%23006' d='M-256 0H768v512H-256z'%2F%3E%3Cpath fill='%23fff' d='M-256 0v57l910 455h114v-57L-141 0h-115zM768 0v57l-909 455h-115v-57L654 0h114z'%2F%3E%3Cpath fill='%23fff' d='M171 0v512h170V0H171zm-427 171v170H768V171H-256z'%2F%3E%3Cpath fill='%23c00' d='M-256 205v102H768V205H-256zM205 0v512h102V0H205zm-461 512L85 341h77l-342 171h-76zm0-512L85 171H9L-256 38V0zm606 171L692 0h76L427 171h-77zm418 341L427 341h76l265 133v38z'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 100%;
}
</style>
