export default class Timer {
  private startedAt: number
  private readonly delay: number
  private timer: NodeJS.Timeout | null = null
  cb: () => void

  constructor(delay: number, cb: () => any) {
    this.cb = cb;
    this.startedAt = Date.now()
    this.delay = delay
    if (this.delay > 0) {
      this.timer = setTimeout(this.cb, delay)
    }
  }

  pause() {
    if (this.timer !== null) {
      clearTimeout(this.timer)
    }
  }

  resume() {
    if (this.timer !== null) {
      clearTimeout(this.timer)
    }
    this.startedAt = Date.now()
    this.timer = setTimeout(this.cb, this.delay)
  }

  stop() {
    if (this.timer !== null) {
      clearTimeout(this.timer)
    }
    this.cb()
  }
}
