import { HttpClient } from "@/services/api/http-client";

export default class HttpClientSingleton {
  private static httpClient: HttpClient;

  static getInstance(): HttpClient {
    if (HttpClientSingleton.httpClient) {
      return HttpClientSingleton.httpClient;
    }

    HttpClientSingleton.httpClient = new HttpClient(
      import.meta.env.VITE_FRONTEND_API_BASE_URL,
    );

    return HttpClientSingleton.httpClient;
  }
}
