<script setup lang="ts">
</script>

<template>
  <div class="p-2 relative w-full h-full crossed-out">
  </div>
</template>

<style scoped>
  .crossed-out:after {
    @apply bg-gray-400;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140%;
    height: 1px;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .crossed-out:before {
    @apply bg-gray-400;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140%;
    height: 1px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
</style>
