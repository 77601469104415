import { defineStore } from "pinia";

export type ImportStore = {
  importDataIsRunning: boolean;
  importImagesIsRunning: boolean;
  lastDataSync: string;
}

export default defineStore('import', {
  state: () => ({
    importDataIsRunning: false,
    importImagesIsRunning: false,
    lastDataSync: '',
  } as ImportStore),
  persist: true,
})
