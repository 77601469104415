import ArticlesView from "@/views/ArticlesView.vue";
import CartView from "@/views/CartView.vue";
import CustomersView from "@/views/CustomersView.vue";
import FinishView from "@/views/FinishView.vue";
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import useUserStore from '../stores/user'
import useOrderStore from "@/stores/order";
import { ORDER_STATUS } from "@/models/Order";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/customers',
      name: 'customers',
      component: CustomersView
    },
    {
      path: '/articles',
      name: 'articles',
      component: ArticlesView
    },
    {
      path: '/cart',
      name: 'cart',
      component: CartView
    },
    {
      path: '/finish/:orderId',
      name: 'finish',
      component: FinishView
    }
  ],
})

router.beforeEach((to) => {
  const store = useUserStore();
  const order = useOrderStore();

  if (to.name !== 'login' && !store.isAuthenticated) {
    return {name: 'login'}
  }

  if (to.name === 'login' && store.isAuthenticated) {
    return {name: 'home'}
  }

  if (to.name === 'cart' && (order.order === null || order.order.status !== ORDER_STATUS.NEW)) {
    return {name: 'home'}
  }
})

export default router
