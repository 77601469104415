import type { Price } from "@/models/Price";

export default class PriceTierResolver {
  static resolvePrice(prices: Price[], quantity: number): number {
    if (prices.length < 1) {
      console.error("No prices found");
      return 0;
    }
    const priceTiers: Price[] = prices.filter(price => {
      return price.from <= quantity;
    });

    return priceTiers.pop()?.price ?? prices[0].price;
  }
}
