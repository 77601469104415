import useUserStore from "@/stores/user";

export type FilterRequest = {
  key: string;
  value: string;
}

export class HttpClient {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async get(url: string, config: RequestInit = {}): Promise<any> {
    return this.#request(url, Object.assign({method: 'GET'}, config));
  }

  async getAll(url: string, config: RequestInit = {}, callback: Function, limit = 100, filter: FilterRequest | null = null): Promise<{
    items: number
  }> {
    if (url.includes('?')) {
      throw new Error('parameter handling not yet implemented');
    }
    const params = new URLSearchParams();

    if (filter) {
      params.append(`filter[${filter.key}]`, filter.value);
    }

    const total = await this.#getEndpointTotal(url, params, config) ?? 0;
    if (total === 0) {
      return Promise.resolve({items: total});
    }
    const totalRequests = Math.ceil(total / limit);

    params.set('limit', limit.toString());
    params.set('lastId', "0");
    for (let i = 0; i < totalRequests; i++) {
      const response = await this.get(`${url}?${params.toString()}`, config);

      params.set('lastId', response.data.slice(-1)[0].id);
      callback(response);
    }

    return Promise.resolve({items: total});
  }

  async post(url: string, config: RequestInit = {}): Promise<any> {
    return this.#request(url, Object.assign({method: 'POST'}, config))
  }

  async put(url: string, config: RequestInit = {}): Promise<any> {
    return this.#request(url, Object.assign({method: 'PUT'}, config))
  }

  async fetchImage(url: string): Promise<any> {
    return this.#fetchWithRetry(url)
      .catch((error) => {
        console.error(`Image ${url} could not be fetched`, error);
      });
  }

  async #getEndpointTotal(url: string, params: URLSearchParams, config: RequestInit = {}): Promise<number> {
    params.set('limit', '0');
    const response = await this.get(`${url}?${params.toString()}`, config);
    return response.total;
  }

  async #request(url: string, config: RequestInit = {}): Promise<any> {
    const defaultConfig = this.#getDefaultConfig();

    if (config.headers) {
      Object.assign(config.headers, defaultConfig.headers);
    }
    const response = await this.#fetchWithRetry(this.baseUrl + url, Object.assign({}, defaultConfig, config));
    if (!response.ok) {
      throw response.status;
    }
    return response.json();
  }

  #getDefaultConfig(): RequestInit {
    const userStore = useUserStore();

    return {
      headers: {
        Authorization: `Basic ${btoa(`${userStore.apiUsername}:${userStore.apiKey}`)}`
      }
    }
  }

  async #fetchWithRetry (url: string, config: RequestInit = {}, retries = 3): Promise<any> {
    return fetch(url, config).catch(async (error) => {
      if (retries > 0) {
        const delay = Math.floor(Math.random() * 1000);
        return new Promise((resolve) => setTimeout(resolve, delay))
          .then(() => this.#fetchWithRetry(url, config, retries - 1));
      }
      throw error;
    });
  }
}
