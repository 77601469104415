import type { Customer } from "@/models/Customer";
import CustomerRepository from "@/repositories/customer-repository";
import type { ResponseData } from "@/services/api/resources/ResponseData";
import HttpClientSingleton from "@/services/factory/http-client-singleton";
import type { ImportInterface } from "@/services/import/import-interface";
import progressStore from "@/stores/progress";
import useUserStore from "@/stores/user";

export class CustomerImport implements ImportInterface {
  #repository: CustomerRepository;

  constructor(repository: CustomerRepository | null = null) {
    this.#repository = repository ?? new CustomerRepository();
  }

  getProgress() {
    return progressStore.getProgress(this.constructor.name)
  }


  async import(): Promise<{ items: number }> {
    const
      progress = progressStore.initProgress(this.constructor.name),
      repositoryCreate = this.#repository.recreate(),
      identifier = useUserStore().identifier,
      repositoryPromises: Promise<any>[] = [];

    repositoryPromises.push(repositoryCreate);

    return HttpClientSingleton.getInstance().getAll('/api/PwaCustomer', {}, (response: ResponseData<Customer[]>) => {
      const customers = response.data.map((customerData: any): Customer => {
        const customer = {} as Customer;
        customer._id = customerData.number;
        customer.firstname = customerData.firstname;
        customer.lastname = customerData.lastname;
        customer.customerGroup = customerData.customerGroup;
        customer.company = customerData.company;
        customer.street = customerData.street;
        customer.city = customerData.city;
        customer.zip = customerData.zipcode;
        customer.accountNumber = customerData.number;
        customer.accountNumberIntern = customerData.accountNumber;
        customer.syncDate = new Date();
        customer.searchField = `${customer.firstname}${customer.lastname}${customer.company}${customer.accountNumberIntern}${customer.street}${customer.city}${customer.zip}`.toLowerCase();
        return customer;
      });
      progress.updateTotal(response.total).addProgress(customers.length);

      repositoryCreate.then(() => repositoryPromises.push(this.#repository.bulkDocs(customers)));
    }, 100, {key: 'identifier', value: identifier}).finally(() => {
      this.createIndexAfterAllTasksFinished(repositoryPromises);
      progress.finish()
    });
  }

  createIndexAfterAllTasksFinished(repositoryPromises: Promise<any>[] = []) {
    Promise.all(repositoryPromises).finally(() => this.#repository.createIndex());
  }
}
