<script setup lang="ts">
import notifications from "@/services/notification";
import IconLoading from "@/components/icons/IconLoading.vue";
import type { NotificationInterface } from "@/services/notification-interface";

function getClass(notification: NotificationInterface) {
  return {
    'bg-blue-100 border-blue-500 text-blue-900': notification.type === 'info',
    'bg-red-100 border-red-500 text-red-900': notification.type === 'error',
    'bg-teal-100 border-teal-500 text-teal-900': notification.type === 'success',
  }
}

function remove(notification: NotificationInterface) {
  notification.kill()
}
</script>

<template>

  <TransitionGroup name="fade" tag="div" class="fixed right-4 bottom-4 space-y-4">
    <div v-for="(notification,index) in notifications.getAll()" :key="index"
         class="px-4 py-3 border-t-4 shadow-md cursor-pointer w-[15rem] relative"
         :class="getClass(notification)" role="alert" @click="remove(notification)"
         :data-qa="notification.type"
         @mouseenter="notification.hasTimer? notification.pause() : null"
         @mouseleave="notification.hasTimer? notification.resume() : null">
      <div :style="{width: notification.progress + '%'}" v-if="notification.progress !== null"
           class="border-t-4 bottom-0 left-0 transition-all max-w-full border-current absolute"></div>
      <div class="flex">
        <IconLoading v-if="notification.progress !== null" class="flex-none w-5 w-5 mr-2 text-gray-400 fill-gray-900"/>
        <div>
          <p class="font-bold" v-text="notification.title"></p>
          <p class="text-sm text-grey" v-html="notification.message" v-if="notification.message"></p>
        </div>
      </div>
    </div>
  </TransitionGroup>

</template>
