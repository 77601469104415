import type { Price } from "@/models/Price";
import type { UserPrice } from "@/models/UserPrice";
import { AbstractRepository } from "@/repositories/abstract-repository";

export default class UserPriceRepository extends AbstractRepository<UserPrice> {
  static readonly databaseName = 'user-price';

  constructor() {
    super(UserPriceRepository.databaseName);
  }

  async search(articleNumber: string, priceGroup: string|null): Promise<Price[]> {
    try {
      if (articleNumber === '' || priceGroup === '' || priceGroup === null) {
        return Promise.resolve([]);
      }

      const result = await this.findById(articleNumber);

      if (result === null) {
        return Promise.resolve([]);
      }

      return result.prices.filter(price => {
        return price.priceGroup === priceGroup;
      })
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve([]);
    }
  }
}
