<script lang="ts" setup>
import ArticleImageComponent from "@/components/article/ArticleImageComponent.vue";
import AvailabilityComponent from "@/components/article/AvailabilityComponent.vue";
import BasketForm from "@/components/article/BasketFormComponent.vue";
import type { Article } from "@/models/Article";
import type { OrderItem } from "@/models/OrderItem";
import type { Translation } from "@/models/Translation";
import GroupPriceRepository from "@/repositories/group-price-repository";
import TranslationRepository from "@/repositories/translation-repository";
import UserPriceRepository from "@/repositories/user-price-repository";
import useOrderStore from "@/stores/order";
import useUserStore from "@/stores/user";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import PriceTierResolver from "@/services/helper/price-tier-resolver";
import type { Price } from "@/models/Price";
import TooltipPriceTierComponent from "@/components/utils/TooltipPriceTierComponent.vue";

const userStore = useUserStore();
const orderStore = useOrderStore();

const { locale } = storeToRefs(userStore);

interface Properties {
  article: Article;
  customerGroup: string | null;
  priceGroup: string | null;
}

const props = defineProps<Properties>();
const ekPrice = ref(0);
const translations = ref(null as Translation | null);
const translationFallback = '-';

let prices: Price[] = [];

onMounted(async () => {
  const userPriceRepository = new UserPriceRepository();
  const groupPriceRepository = new GroupPriceRepository();

  loadTranslation();

  prices = await userPriceRepository.search(props.article.productNumber, props.priceGroup);

  if (prices.length < 1) {
    prices = await groupPriceRepository.search(props.article.productNumber, props.customerGroup);
  }
  ekPrice.value = PriceTierResolver.resolvePrice(prices, 0);
})

watch(locale, () => {
  loadTranslation();
})

function loadTranslation() {
  const translationRepository = new TranslationRepository();

  translationRepository.findByArticleNumberAndLocale(props.article.productNumber, locale.value)
    .then(translationsResult => translations.value = translationsResult);
}

function translateWithFallback(translations: any, field: string, fallback: string) {
  if (translations && translations[field] && translations[field] !== '') {
    return translations[field];
  }

  return fallback;
}

async function updatePrice(amount: number) {
  if (prices.length < 1) {
    return;
  }
  ekPrice.value = PriceTierResolver.resolvePrice(prices, amount);
}

async function layIntoOrder(amount: number) {
  const orderItem = {
    articleName: translateWithFallback(translations.value, 'name', translationFallback),
    articleNumber: props.article.productNumber,
    quantity: amount,
    price: ekPrice.value,
    thumbnail: props.article.thumbnail,
    prices: prices,
  } as OrderItem;

  await orderStore.addOrderItem(orderItem);
}
</script>

<template>
  <div class="w-full container mx-auto mt-4 grid grid-flow-col grid-cols-[6fr_3fr_1fr] article">
    <div class="flex">
      <ArticleImageComponent :alt="article.productNumber" :image-url="article.thumbnail"></ArticleImageComponent>
      <div class="ml-10 flex justify-center flex-col text-[15px]">
        <div class="mb-2 font-bold" data-qa="article.name">
          {{ translateWithFallback(translations, 'name', translationFallback) }}
        </div>
        <div data-qa="article.number">
          {{ $t("article.number") }}: {{ article.productNumber }}
        </div>
        <div data-qa="article.ean">
          {{ $t("article.ean") }}: {{ article.ean }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-around">
      <div class="w-32">
        <span v-if="article.uvp > 0" data-qa="article.uvp">
          {{ $t("article.uvp") }} {{ $n(article.uvp, 'currency') }}
        </span>
      </div>
      <div class="w-32">
        <div data-qa="article.hek">
          {{ $t("article.hek") }} {{ $n(ekPrice, 'currency') }}
          <TooltipPriceTierComponent :prices="prices"></TooltipPriceTierComponent>
        </div>
        <AvailabilityComponent :stock="article.instock" :next-shipping-date="article.nextShippingDate" class="absolute"></AvailabilityComponent>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <BasketForm @updatePrice="updatePrice" @amountChoosen="layIntoOrder"></BasketForm>
    </div>
  </div>
</template>
