<script lang="ts" setup>
import PrimaryButtonComponent from "@/components/buttons/PrimaryButtonComponent.vue";
import TableComponent, { Action } from "@/components/TableComponent.vue";
import CustomerRepository from "@/repositories/customer-repository";
import OrderRepository from "@/repositories/order-repository";
import router from "@/router";
import { onMounted, reactive } from "vue";
import useOrderStore from "@/stores/order";
import useImportStore from "@/stores/import";
import { ImportHandler } from "@/services/import/import-handler";
import type { Order } from "@/models/Order";
import { ORDER_STATUS } from "@/models/Order";
import CartCalculator from "@/services/helper/cart-calculator";
import { storeToRefs } from "pinia";

const orderRepository = new OrderRepository();
const importHandler = new ImportHandler();

const data = reactive({
  body: [] as Order[],
})

const actions = [
  {
    condition: showDeleteAction,
    action: Action.delete,
  }
]

const orderStore = useOrderStore();
const importStore = useImportStore();

const {importDataIsRunning, importImagesIsRunning, lastDataSync} = storeToRefs(importStore)

onMounted(async () => {
  const customerRepository = new CustomerRepository();
  const orders = await orderRepository.findAllWithDescendingOrder();
  // @todo find some cool way do automatically join all relations
  const customers = await customerRepository.findByIds(orders.map(order => order.customerNumber));

  data.body = orders.map(order => {
    return Object.assign(order, {
      customer: customers.find(customer => customer._id === order.customerNumber)?.company,
      article: order.items.length + '',
      total: CartCalculator.calculateTotal(order.items),
    })
  });
})

async function handleUpdateData() {
  await importHandler.importAllRelevantData();
}

async function handleUpdateImages() {
  await importHandler.importAllImages();
}

async function handleAction(row: Order, action: string) {
  if (action === Action.delete && row.status === ORDER_STATUS.NEW) {
    await orderRepository.remove(row._id);
    data.body.splice(data.body.indexOf(row), 1);
  }
}

async function handleRowClick(row: Order) {
  if (row.status === ORDER_STATUS.NEW) {
    await orderStore.loadOrder(row._id);
    await router.push({name: 'articles'});
    return;
  }
  await router.push({name: 'finish', params: {orderId: row._id}});
}

function showDeleteAction(row: Order): boolean {
  return row.status === ORDER_STATUS.NEW;
}

</script>

<template>
  <div class="container mx-auto mt-4">
    <div>
      <PrimaryButtonComponent class="import-data-button disabled:opacity-50 disabled:bg-grey-light"
                              :disabled="importDataIsRunning"
                              @click="handleUpdateData">
        {{
          $t("home.update_data")
        }}
      </PrimaryButtonComponent>
      <PrimaryButtonComponent class="import-images-button disabled:opacity-50 disabled:bg-grey-light"
                              :disabled="importImagesIsRunning"
                              @click="handleUpdateImages">
        {{ $t("home.update_images") }}
      </PrimaryButtonComponent>
    </div>

    <div v-if="lastDataSync" data-qa="last-data-sync">
      {{ $t("home.last_data_sync") }} {{ lastDataSync }}
    </div>

    <div>
      <div class="font-bold text-grey mt-10 mb-2">
        {{ $t("home.current_orders") }}
      </div>
      <TableComponent
        :actions="actions"
        :data="data.body"
        translationPrefix="home.table"
        :mapping="['_id', 'orderNumber', 'customer', 'status', 'article', 'orderDate', 'total']"
        :formatters="{5: 'date', 6: 'currency'}"
        @row-clicked="handleRowClick"
        @action-triggered="handleAction"
      >
      </TableComponent>
    </div>
  </div>
</template>

<style scoped>
</style>
