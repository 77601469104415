<script lang="ts" setup>
import ArticleComponent from "@/components/article/ArticleComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import SearchFieldComponent from "@/components/SearchFieldComponent.vue";
import type { Article } from "@/models/Article";
import ArticleRepository from "@/repositories/article-repository";
import CustomerRepository from "@/repositories/customer-repository";
import useOrderStore from "@/stores/order";
import { onMounted, onUnmounted, ref } from "vue";

const orderStore = useOrderStore();

const articles = ref([] as Article[]);
const articleRepository = new ArticleRepository();
const customerRepository = new CustomerRepository();
let page = 1;
const pageSize = 10;
const filteredArticles = ref([] as Article[]);
const allArticles = ref([] as Article[]);
const loading = ref(false);
const customerGroup = ref('' as string|null);
const priceGroup = ref('' as string|null);

function handleScroll() {
  if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 200) {
    page++;
    refreshPage();
  }
}

function refreshPage() {
  articles.value.push(
    ...filteredArticles.value.slice((page - 1) * pageSize, page * pageSize)
  )
}

onMounted(async () => {
  if (!orderStore.order) {
    return;
  }

  const customer = await customerRepository.findById(orderStore.order.customerNumber);
  customerGroup.value = customer?.customerGroup ?? null;
  priceGroup.value = customer?.priceGroup ?? null;

  await searchTermChanged('', true);
  handleScroll();
  window.addEventListener('scroll', handleScroll);
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

async function searchTermChanged(searchTerm: string, initial: boolean = false) {
  page = 1;
  loading.value = true;
  filteredArticles.value = await articleRepository.search(searchTerm, customerGroup.value, allArticles.value);

  if (initial) {
    allArticles.value = filteredArticles.value;
  }

  articles.value = [];
  refreshPage();
  loading.value = false;
}

</script>

<template>
  <div class="w-full container mx-auto mt-4">
    <SearchFieldComponent @termChanged="searchTermChanged">
      <template #tooltip>
        <div v-html="$t('tooltip.article_search')">
        </div>
      </template>
    </SearchFieldComponent>
    <LoadingComponent :loading="loading">
      <div class="w-full"
           :class="{'h-screen': articles.length === 0}"
      >
        <ArticleComponent v-for="article in articles" :key="article._id" :article="article" :customer-group="customerGroup" :price-group="priceGroup" :data-qa="article._id"></ArticleComponent>
      </div>
    </LoadingComponent>
  </div>
</template>
