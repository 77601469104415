import { defineStore } from 'pinia';
import AuthenticationAPI from "@/services/api/authentication";
import type { AuthenticationData } from "@/services/api/resources/AuthenticationData";

export type UserStore = {
  isAuthenticated: boolean;
  identifier: string;
  apiKey: string;
  apiUsername: string;
  locale: string;
}

export default defineStore('user', {
  state: () => ({
    isAuthenticated: false,
    identifier: '',
    apiKey: '',
    apiUsername: '',
    locale: 'de-DE'
  } as UserStore),
  persist: true,

  actions: {
    async login(formData: FormData): Promise<void> {
      const response: AuthenticationData = await AuthenticationAPI.login(formData);

      this.isAuthenticated = true;
      this.identifier = response.identifier;
      this.apiKey = response.apiKey;
      this.apiUsername = response.user;
    },
    async logout(): Promise<void> {
      this.isAuthenticated = false;
      this.identifier = '';
      this.apiKey = '';
      this.apiUsername = '';
    }
  }
})
