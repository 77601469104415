import ArticleRepository from "@/repositories/article-repository";
import HttpClientSingleton from "@/services/factory/http-client-singleton";
import progressStore from "@/stores/progress";
import type { ResponseData } from "@/services/api/resources/ResponseData";
import type { ImportInterface } from "@/services/import/import-interface";
import type { Article } from "@/models/Article";

export class ArticleImport implements ImportInterface {
  #repository: ArticleRepository;

  constructor(repository: ArticleRepository | null = null) {
    this.#repository = repository ?? new ArticleRepository();
  }

  getProgress() {
    return progressStore.getProgress(this.constructor.name)
  }

  async import(): Promise<{ items: number }> {
    const
      progress = progressStore.initProgress(this.constructor.name),
      repositoryCreate = this.#repository.recreate(),
      repositoryPromises: Promise<any>[] = [];

    repositoryPromises.push(repositoryCreate);

    return HttpClientSingleton.getInstance().getAll('/api/PwaArticle', {}, (response: ResponseData<Article[]>) => {
      const articles = response.data.map((articleData: any): Article => {
        const article = {} as Article;
        article._id = articleData.productNumber;
        article.productNumber = articleData.productNumber;
        article.ean = articleData.ean;
        article.bidex = articleData.bidex;
        article.thumbnail = articleData.thumbnail;
        article.customerGroups = articleData.groupkeys as string[];
        article.syncDate = new Date();
        article.instock = parseInt(articleData.instock);
        article.nextShippingDate = articleData.nextShippingDate;
        article.uvp = parseFloat(articleData.uvp);
        article.searchField = `${articleData.productNumber}${articleData.ean}${articleData.bidex}`.toLowerCase();
        return article;
      });
      progress.updateTotal(response.total).addProgress(articles.length);
      repositoryCreate.then(() => repositoryPromises.push(this.#repository.bulkDocs(articles)));
    }, 1000).finally(() => {
      this.createIndexAfterAllTasksFinished(repositoryPromises);
      progress.finish();
    });
  }

  createIndexAfterAllTasksFinished(repositoryPromises: Promise<any>[] = []) {
    Promise.all(repositoryPromises).finally(() => this.#repository.createIndex());
  }
}
