<script lang="ts" setup>
import SearchFieldComponent from "@/components/SearchFieldComponent.vue";
import TableComponent, { Action } from "@/components/TableComponent.vue";
import type { Customer } from "@/models/Customer";
import CustomerRepository from "@/repositories/customer-repository";
import router from "@/router";
import useOrderStore from "@/stores/order";
import { onMounted, ref, watch } from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

let customerRepository: CustomerRepository;

const actions = [
  {
    action: Action.create,
  }
]

const orderStore = useOrderStore();
const body = ref([] as Customer[]);
const searchTerm = ref('');
const loading = ref(true);

onMounted(async () => {
  customerRepository = new CustomerRepository();
  loadData(searchTerm.value)
})

watch(searchTerm, loadData)

function loadData(st: string) {

  loading.value = true;
  return customerRepository.search(st)
    .then(data => (body.value = data))
    .finally(() => (loading.value = false));
}

async function handleRowClick(row: Customer) {
  await submitOrder(row._id);
}

async function handleAction(row: Customer, action: string) {
  if (action === Action.create) {
    await submitOrder(row._id);
  }
}

async function submitOrder(id: string) {
  await orderStore.createOrder(id);
  await router.push('/articles');
}
</script>

<template>
  <div class="w-full container mx-auto mt-4">
    <SearchFieldComponent @termChanged="newTerm => searchTerm = newTerm"></SearchFieldComponent>
    <LoadingComponent :loading="loading">
      <TableComponent
        :actions="actions"
        :data="body"
        translationPrefix="customers"
        :mapping="['company', 'accountNumberIntern', 'street', 'city', 'zip']"
        @row-clicked="handleRowClick"
        @action-triggered="handleAction"
      >
      </TableComponent>
    </LoadingComponent>
  </div>
</template>
