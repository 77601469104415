import HttpClientSingleton from "@/services/factory/http-client-singleton"
import progressStore from "@/stores/progress"
import UserPriceRepository from "@/repositories/user-price-repository"
import type {ResponseData} from "@/services/api/resources/ResponseData"
import type {ImportInterface} from "@/services/import/import-interface"
import type {UserPrice} from "@/models/UserPrice"

export class UserPriceImport implements ImportInterface {
  #repository: UserPriceRepository

  constructor(
    repository: UserPriceRepository | null = null
  ) {
    this.#repository = repository ?? new UserPriceRepository()
  }

  getProgress() {
    return progressStore.getProgress(this.constructor.name)
  }

  async import(): Promise<{ items: number }> {
    const
      progress = progressStore.initProgress(this.constructor.name),
    repositoryCreate = this.#repository.recreate()

    return HttpClientSingleton.getInstance().getAll('/api/PwaArticleUserPrice', {}, (response: ResponseData<UserPrice[]>) => {
      const userPrice = response.data.map((userPriceData: any): UserPrice => ({
        _id: userPriceData.productNumber,
        prices: userPriceData.customerPrices.map((price: any) => ({
          price: price.price,
          from: price.from,
          priceGroup: price.pricegroup
        })),
        syncDate: new Date()
      } as UserPrice));

      progress.updateTotal(response.total).addProgress(userPrice.length)
      repositoryCreate.then(() => this.#repository.bulkDocs(userPrice))
    }, 200).finally(() => {
      progress.finish()
    })
  }
}
