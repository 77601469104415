<script lang="ts" setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import useUserStore from "@/stores/user";
import { storeToRefs } from "pinia";
import { RouterView } from 'vue-router'
import ToastNotificationComponent from "@/components/ToastNotificationComponent.vue";
import type { Order } from "@/models/Order";
import OrderRepository from "@/repositories/order-repository";
import { OrderExport } from "@/services/export/order-export";

const userStore = useUserStore();
const {isAuthenticated} = storeToRefs(userStore)

window.addEventListener('online', () => {
  const orderRepository = new OrderRepository(),
    orderExport = new OrderExport();


  orderRepository.findAllPendingOrders().then(async (orders: Order[]) => {
    orders.forEach((order) => {
      orderExport.export(order);
    });
  })
});
</script>

<template>
  <div v-if="isAuthenticated">
    <HeaderComponent></HeaderComponent>
  </div>
  <RouterView/>
  <ToastNotificationComponent/>
</template>

<style scoped>
</style>
