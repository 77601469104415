import HttpClientSingleton from "@/services/factory/http-client-singleton";
import progressStore from "@/stores/progress";
import GroupPriceRepository from "@/repositories/group-price-repository";
import type {ResponseData} from "@/services/api/resources/ResponseData";
import type {ImportInterface} from "@/services/import/import-interface";
import type {GroupPrice} from "@/models/GroupPrice";

export class PriceImport implements ImportInterface {
  #repository: GroupPriceRepository;

  constructor(repository: GroupPriceRepository | null = null) {
    this.#repository = repository ?? new GroupPriceRepository();
  }

  getProgress() {
    return progressStore.getProgress(this.constructor.name)
  }

  async import(): Promise<{ items: number }> {
    const
      progress = progressStore.initProgress(this.constructor.name),
      repositoryCreate = this.#repository.recreate();

    return HttpClientSingleton.getInstance().getAll('/api/PwaArticlePrice', {}, (response: ResponseData<GroupPrice[]>) => {
      const groupPrice = response.data.map((groupPriceData: any): GroupPrice => ({
        _id: groupPriceData.productNumber,
        prices: groupPriceData.prices.map((price: any) => ({
          price: price.price,
          from: price.from,
          priceGroup: price.pricegroup
        })),
        syncDate: new Date()
      } as GroupPrice));

      progress.updateTotal(response.total).addProgress(groupPrice.length);
      repositoryCreate.then(() => this.#repository.bulkDocs(groupPrice))
    }, 200).finally(() => {
      progress.finish()
    })
  }

}
