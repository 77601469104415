<script lang="ts" setup>
import IconInfo from "@/components/icons/IconInfo.vue";
import TooltipComponent from "@/components/utils/TooltipComponent.vue";
import { ref } from "vue";

const emit = defineEmits<{
  (event: 'termChanged', searchTerm: string): void
}>();

const searchTerm = ref('');


let timeout: number | undefined;
function debounce (fnc: Function, delayMs: number) {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    fnc();
  }, delayMs || 500) as any;
}

function onInput() {
  if (searchTerm.value !== '' && searchTerm.value.length < 3)
    return;
  debounce(() => emit('termChanged', searchTerm.value), 800);
}
</script>

<template>
  <div class="font-bold text-grey mt-10 mb-2" data-qa="search-title">
    {{ $t("search.title") }}
    <TooltipComponent class="text-left" v-if="$slots.tooltip">
      <template #content>
        <slot name="tooltip"></slot>
      </template>
      <IconInfo></IconInfo>
    </TooltipComponent>
  </div>
  <input type="text"
         class="w-full border-2 mb-4 p-1 text-grey"
         v-model="searchTerm"
         @input="onInput"
         name="search"
         data-qa="search-input"
         :placeholder="$t('search.placeholder')"
  >
</template>

<style>
</style>
