import type { Order } from "@/models/Order";
import { ORDER_STATUS } from "@/models/Order";
import type { OrderItem } from "@/models/OrderItem";
import OrderRepository from "@/repositories/order-repository";
import { defineStore } from 'pinia';
import { toRaw } from "vue";

export type OrderStore = {
  order: Order | null;
}

export default defineStore('order', {
  state: () => ({
    order: null,
  } as OrderStore),
  persist: true,

  actions: {
    async createOrder(customerNumber: string): Promise<Order> {
      const orderRepository = new OrderRepository();
      const generatedId = await orderRepository.generateId();
      const order = {
        _id: generatedId,
        orderNumber: 'ORD-' + generatedId.padStart(4, '0'),
        customerNumber: customerNumber,
        orderDate: new Date(),
        status: ORDER_STATUS.NEW,
        items: [],
        additionalInfo: '',
        syncDate: new Date(),
      } as Order;

      await orderRepository.bulkDocs([order]);

      this.order = order;
      return order;
    },

    async addOrderItem(orderItem: OrderItem): Promise<Order|null> {
      if (this.order === null) {
        return Promise.resolve(null);
      }
      const orderRepository = new OrderRepository();
      this.order.items.push(orderItem);
      const rawOrder = toRaw(this.order);
      await orderRepository.put(rawOrder);
      return this.order;
    },

    async loadOrder(orderNumber: string): Promise<Order | null> {
      const orderRepository = new OrderRepository();
      const order = await orderRepository.findById(orderNumber);

      this.order = order;
      return order;
    },

    async removeOrderItem(orderItem: OrderItem): Promise<Order | null> {
      if (this.order === null) {
        return Promise.resolve(null);
      }
      this.order.items.splice(this.order.items.indexOf(orderItem), 1);

      return this.updateOrder();
    },

    async updateOrderStatus(status: ORDER_STATUS): Promise<Order | null> {
      if (this.order === null) {
        return Promise.resolve(null);
      }
      this.order.status = status;

      return this.updateOrder();
    },

    async updateOrder(): Promise<Order | null> {
      if (this.order === null) {
        return Promise.resolve(null);
      }
      const rawOrder = toRaw(this.order);

      const orderRepository = new OrderRepository();
      await orderRepository.put(rawOrder);

      return this.order;
    },
  }
})
