import type { ExportInterface } from "@/services/export/export-interface";
import type { Order } from "@/models/Order";
import { ORDER_STATUS } from "@/models/Order";
import type { ExportResponseData, OrderCreateData, OrderCreateItemData } from "@/services/api/resources/OrderData";
import OrderAPI from "@/services/api/order";
import OrderRepository from "@/repositories/order-repository";
import CartCalculator from "@/services/helper/cart-calculator";

export class OrderExport implements ExportInterface<Order> {
  async export(order: Order): Promise<ExportResponseData> {
    const orderRepository = new OrderRepository(),
      orderCreateItem: OrderCreateItemData[] = [];

    for (const item of order.items) {
      orderCreateItem.push({
        price: item.price,
        statusId: 0,
        articleName: item.articleName,
        articleNumber: item.articleNumber,
        quantity: item.quantity,
      });
    }

    const sumTotal = CartCalculator.calculateTotal(order.items),
      customerNumber = order?.customerNumber ?? '',
      orderCreateData: OrderCreateData = {
        customerNumber: customerNumber,
        customerReferenceNumber: order?.additionalInfo,
        company: order?.company,
        street: order?.street,
        zip: order?.zip,
        city: order?.city,
        invoiceAmount: sumTotal,
        invoiceAmountNet: sumTotal,
        orderItems: orderCreateItem,
      };

    return OrderAPI.create(orderCreateData).then(async (response) => {
      order.status = ORDER_STATUS.COMPLETE;
      await orderRepository.put(order);
      return response;
    }).catch(async (error) => {
      console.error('Order could not be exported for customer ' + order.customerNumber, error);
      order.status = ORDER_STATUS.FAILED;
      await orderRepository.put(order);
      return error;
    });
  }
}
