import type { OrderItem } from "@/models/OrderItem";

export enum ORDER_STATUS {
  NEW = 'new',
  PENDING = 'pending',
  COMPLETE = 'complete',
  FAILED = 'failed',
}

export interface Order {
  _id: string;
  orderNumber: string;
  customerNumber: string;
  orderDate: Date;
  status: ORDER_STATUS;
  items: OrderItem[];
  additionalInfo: string;
  company?: string;
  street?: string;
  zip?: string;
  city?: string;
  syncDate: Date | null;
}
