import { AbstractRepository } from "@/repositories/abstract-repository";
import type { Translation } from "@/models/Translation";

export default class TranslationRepository extends AbstractRepository<Translation> {
  static readonly databaseName = 'translations';

  constructor() {
    super(TranslationRepository.databaseName);
  }

  async createIndex() {
    this.database.createIndex({
      index: {
        fields: [
          'searchField',
          'language',
        ]
      }
    })
  }

  async findByArticleNumberAndLocale(productNumber: string, locale: string): Promise<Translation|null> {
    try {
      return await this.findById(`${productNumber}_${locale}`);
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve(null);
    }
  }

  async search(searchTerm: string, locale: string): Promise<Translation[]> {
    try {
      return await this.findAll().then((result: any) => {
        return result.filter((row: any) => {
          return row.language === locale && row.searchField.match(searchTerm.toLowerCase());
        });
      });
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return [];
    }
  }
}
