<script lang="ts" setup>
import SecondaryButtonComponent from "@/components/buttons/SecondaryButtonComponent.vue";
import { watch } from "vue";
import usePaginator from 'vue-use-paginator'

interface Properties {
  numItems: number;
  pageSize?: number;
  numButtons?: number;
}

const emit = defineEmits<{
  (event: 'page-change', page: number): void
}>();


const props = withDefaults(defineProps<Properties>(), {
  pageSize: () => 10,
  numButtons: () => 5,
})

const {
  page,
  numItems,
  buttons,
  hasPrev,
  hasNext,
  goStart,
  goNext,
  goEnd,
} = usePaginator({
  pageSize: props.pageSize,
  numItems: props.numItems,
  numButtons: props.numButtons,
});

watch(() => props.numItems, (newValues) => {
  numItems.value = newValues as number;
});

watch(page, (newPage) => {
  emit('page-change', newPage);
})
</script>

<template>
  <div class="pt-4">
    <div class="mx-auto w-max">
      <SecondaryButtonComponent :disabled="!hasPrev && 'disabled'" @click="goStart">
        {{ '<<' }}
      </SecondaryButtonComponent>
      <SecondaryButtonComponent :disabled="!hasPrev && 'disabled'" @click="goStart">
        {{ '<' }}
      </SecondaryButtonComponent>

      <SecondaryButtonComponent
        v-for="button in buttons"
        :key="button.page"
        :class="{ 'active': button.active }"
        @click="page = button.page"
      >
        {{ button.ellipsis ? '...' : button.page }}
      </SecondaryButtonComponent>

      <SecondaryButtonComponent :disabled="!hasNext && 'disabled'" @click="goNext">
        {{ '>' }}
      </SecondaryButtonComponent>
      <SecondaryButtonComponent :disabled="!hasNext && 'disabled'" @click="goEnd">
        {{ '>>' }}
      </SecondaryButtonComponent>
    </div>
  </div>
</template>
