import PouchDB from "pouchdb";

const databaseStore: PouchDB.Database<any>[] = [];

export abstract class AbstractRepository<T extends { _id: string; }> {
  protected database: PouchDB.Database<T>;

  protected constructor(databaseName: string) {
    this.database = this.#getDatabase(databaseName);
  }

  async recreate() {
    await this.database.destroy();
    databaseStore.splice(databaseStore.findIndex(db => db.name === this.database.name), 1);
    this.database = this.#createNewDatabase(this.database.name);
  }

  async getTotalDocs(): Promise<number> {
    try {
      const result = await this.database.info();
      return result.doc_count;
    } catch (error) {
      console.error('Error getting total docs from ' + this.database.name + ':', error);
      return Promise.resolve(0);
    }
  }

  async findAll(options = {}): Promise<T[]> {
    try {
      const defaultOptions = {include_docs: true};
      const result: PouchDB.Core.AllDocsResponse<T> = await this.database.allDocs(Object.assign(defaultOptions, options));
      return result.rows.map(row => row.doc as T);
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve([]);
    }
  }

  async findById(id: string): Promise<T | null> {
    try {
      return await this.database.get(id);
    } catch (error) {
      console.error('Error finding ' + this.database.name + ' by id:', error);
      return Promise.resolve(null);
    }
  }

  async remove(id: string): Promise<void> {
    try {
      const doc = await this.database.get(id);
      await this.database.remove(doc);
      await this.database.compact();
      return Promise.resolve();
    } catch (error) {
      console.error('Error finding ' + this.database.name + ' by id:', error);
      return Promise.resolve();
    }
  }

  async bulkDocs(documents: T[]): Promise<any> {
    try {
      return await this.database.bulkDocs(documents);
    } catch (error) {
      console.error('Error inserting bulkDocs in: ' + this.database.name, error);
      return Promise.resolve(null);
    }
  }

  async put(object: T): Promise<any> {
    try {
      const updateObject = await this.database.get(object._id);
      Object.assign(updateObject, object);
      return await this.database.put(updateObject, {force: true});
    } catch (error) {
      console.error('Error inserting bulkDocs in: ' + this.database.name, error);
      return Promise.resolve(null);
    }
  }

  #getDatabase(databaseName: string): PouchDB.Database<T> {
    const database = databaseStore.find((database) => database.name === databaseName);

    if (database) {
      return database;
    }
    return this.#createNewDatabase(databaseName);
  }

  #createNewDatabase(databaseName: string): PouchDB.Database<T> {
    const database = new PouchDB<T>(databaseName);
    databaseStore.push(database);
    return database;
  }
}
