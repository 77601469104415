import ArticleRepository from "@/repositories/article-repository";
import type { ImportInterface } from "@/services/import/import-interface";
import HttpClientSingleton from "@/services/factory/http-client-singleton";
import progressStore from "@/stores/progress";

export class ImageImport implements ImportInterface {
  #articleRepository: ArticleRepository;

  constructor(articleRepository: ArticleRepository | null = null) {
    this.#articleRepository = articleRepository ?? new ArticleRepository();
  }

  getProgress() {
    return progressStore.getProgress(this.constructor.name)
  }

  async import(): Promise<{ items: number }> {
    const progress = progressStore.initProgress(this.constructor.name);
    const httpClient = HttpClientSingleton.getInstance();
    const thumbnails = await this.#articleRepository.findAllWithImages()
    const batchSize = 10;
    const uniqueThumbnails = [...new Set(thumbnails)];
    const total = uniqueThumbnails.length;

    await this.#removeImages();

    progress.updateTotal(total)
    for (let i = 0; i < total; i += batchSize) {
      const batch = uniqueThumbnails.slice(i, i + batchSize);
      await Promise.all(batch.map((thumbnail) => httpClient.fetchImage(thumbnail))).then(() => {
        progress.addProgress(batch.length);
      });
    }

    return {items: total};
  }

  async #removeImages(): Promise<boolean> {
    return caches.delete('images-cache');
  }
}
