<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import OrderRepository from "@/repositories/order-repository";
import CartListComponent from "@/components/article/CartListComponent.vue";
import type { Ref } from "vue";
import { onMounted, ref } from "vue";
import type { Order } from "@/models/Order";
import SecondaryButtonComponent from "@/components/buttons/SecondaryButtonComponent.vue";

const route = useRoute(),
  router = useRouter(),
  orderNumber = route.params.orderId.toString(),
  orderRepository = new OrderRepository();
let order: Ref<Order | null> = ref(null);

onMounted(async () => {
  order.value = await orderRepository.findById(orderNumber);
  if (order.value === null) {
    await router.push({name: 'home'});
  }
});
</script>

<template>
  <div v-if="order != null && order.items.length > 0" class="container mx-auto mt-10">
    <CartListComponent v-model:order="order" :is-cart="false"></CartListComponent>
    <SecondaryButtonComponent ref="button_print" class="block space-x-4 mx-auto mt-4 whitespace-nowrap"
                              onclick="window.print()">
      {{ $t("cart.button_print") }}
    </SecondaryButtonComponent>
  </div>
</template>

<style>
</style>
