<script lang="ts" setup>
import useUserStore from "@/stores/user";
import { ref } from "vue";
import router from "@/router";
import LanguageComponent from "@/components/LanguageComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import { ImportHandler } from "@/services/import/import-handler";

const store = useUserStore();
const isError = ref(false);
const loading = ref(false);

async function login(submitEvent: Event) {
  try {
    const importHandler = new ImportHandler();
    const formElement = submitEvent.currentTarget as HTMLFormElement;
    const formData = new FormData(formElement);
    isError.value = false;
    loading.value = true;
    await store.login(formData);
    importHandler.importAll();
    await router.push({name: "home"});
  } catch (error) {
    console.error(error);
    isError.value = true;
    loading.value = false;
  }
}
</script>

<template>
  <div class="bg-grey-dark h-screen flex flex-col">
    <div :class="[isError?'opacity-100':  'opacity-0']"
         class="transition text-center bg-red-100 border border-red-400 text-red-700 px-4 py-3"
         role="alert">
      <strong class="font-bold">Beim einloggen ist ein fehler aufgetreten. Bitte melden sie sich an den Support</strong>
    </div>
    <div class="flex-grow items-center justify-center flex flex-col">
      <loadingComponent :loading="loading">
        <div class="md:flex md:w-[550px]">
          <div class="bg-grey pt-6 pb-5 text-center md:pb-0 md:w-1/2">
            <img alt="Cosmic Sports" class="m-auto w-4/5" src="@/assets/logo-cosmicsport-stacked-white.webp"/>
            <span class="text-primary text-xs pt-4">YOUR CYCLING UNIVERSE</span>
            <LanguageComponent/>
          </div>
          <div class="bg-white md:w-1/2">
            <div class="py-8 px-4 font-semibold">
              <h2 class="text-xl pb-4">LOGIN</h2>
              <form class="text-grey text-sm" @submit.prevent="login">
                <div class="mb-3">
                  <label class="inline-block mb-2">{{ $t("login.username") }}</label>
                  <input :placeholder="$t('login.enter_username')"
                         class="shadow appearance-none border rounded border-gray-400 p-2 w-full"
                         name="username"
                         required type="text"/>
                </div>
                <div class="mb-3">
                  <label class="inline-block mb-2 font-cosmic">{{ $t("login.password") }}</label>
                  <input :placeholder="$t('login.enter_password')"
                         class="shadow appearance-none border rounded border-gray-400 p-2 w-full"
                         name="password"
                         required type="password"/>
                </div>
                <button class="bg-primary text-white p-2 w-full hover:bg-grey" type="submit">
                  {{ $t("login.sign_in") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </loadingComponent>

      <div class="text-white text-center text-xs p-2">
        <span>{{ $t("login.info_text") }}</span><br/>
        <i18n-t keypath="login.telephone" tag="span">
          <a class="text-primary" href="tel:+4991131075559">+49 911 310755 59</a>
        </i18n-t>
        <br/>
        <i18n-t keypath="login.mail" tag="span">
          <a class="text-primary" href="mailto:support@cosmicsports.de">support@cosmicsports.de</a>
        </i18n-t>
      </div>

    </div>
  </div>
</template>
