import type {ErrorData, ResponseData} from "@/services/api/resources/ResponseData";
import HttpClientSingleton from "@/services/factory/http-client-singleton";
import type {AuthenticationData} from "@/services/api/resources/AuthenticationData";

export default class AuthenticationAPI {
  static login(formData: FormData): Promise<AuthenticationData> {
    return HttpClientSingleton.getInstance()
      .post('/contactPersonsAuthentication/auth', {body: formData})
      .then((responseData: ResponseData<AuthenticationData | ErrorData>) => {
        if (!responseData.success) {
          throw "message" in responseData.data ? responseData.data.message : 'API Error';
        }
        return responseData.data as AuthenticationData;
      });
  }
}
