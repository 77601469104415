import i18n from "@/locales/i18n";
import useUserStore from "@/stores/user";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/vue";
import { createPinia, storeToRefs } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import PouchDB from "pouchdb";

// @ts-ignore
import pouchdbDebug from "pouchdb-debug";
import pouchdbFind from "pouchdb-find";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { createApp, watch } from 'vue'
import App from '@/App.vue'

import './assets/main.css'
import router from './router'

useRegisterSW({ immediate: true });

PouchDB.plugin(pouchdbFind);
PouchDB.plugin(pouchdbDebug);

if (import.meta.env.VITE_POUCHDB_DEBUG === 'True') {
  PouchDB.debug.enable('*');
}

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(i18n);

i18n.global.locale.value = useUserStore().locale as "de-DE" | "en-GB";

app.mount('#app');

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: (import.meta.env.VITE_SENTRY_RELEASE ?? 'unknown'),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    environment: import.meta.env.MODE,
    tracingOptions: {
      trackComponents: true,
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  const { isAuthenticated, identifier, apiUsername } = storeToRefs(useUserStore());
  watch(isAuthenticated, (isAuthenticated) => {
    Sentry.setUser(isAuthenticated ? { id: identifier.value, username: apiUsername.value } : null);
  });
}
