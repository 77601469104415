import type { GroupPrice } from "@/models/GroupPrice";
import type { Price } from "@/models/Price";
import { AbstractRepository } from "@/repositories/abstract-repository";

export default class GroupPriceRepository extends AbstractRepository<GroupPrice> {
  static readonly databaseName = 'group-price';

  constructor() {
    super(GroupPriceRepository.databaseName);
  }

  async search(articleNumber: string, priceGroup: string|null): Promise<Price[]> {
    try {
      if (articleNumber === '' || priceGroup === '' || priceGroup === null) {
        return Promise.resolve([]);
      }

      const result = await this.findById(articleNumber);

      if (result === null) {
        return Promise.resolve([]);
      }

      return result.prices.filter(price => {
        return price.priceGroup === priceGroup;
      })
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve([]);
    }
  }
}
