function between(start: number, stop: number) {
  let s, m, h, _ = stop - start

  const ms = (_) - 1000 *
    ((s = (_ = Math.floor(_ / 1000)) - 60 * (
      m = (_ = Math.floor(_ / 60)) - 60 * (
        h = Math.floor(_ / 60))) - 60 * 60 * h) + 60 * (60 * h + m))
  return {
    hours: h,
    minutes: m,
    seconds: s,
    milliseconds: ms
  }
}

type formatBetweenParts = {
  h?: boolean,
  m?: boolean,
  s?: boolean,
  ms?: boolean
}

function formatBetween(start: number, stop: number, parts: formatBetweenParts = {
  h: true,
  m: true,
  s: true
}) {
  const
    {hours, minutes, seconds, milliseconds} = between(start, stop),
    h = hours > 0 ? `${hours}h ` : '',
    m = minutes > 0 ? `${minutes}m ` : '',
    s = seconds > 0 ? `${seconds}s ` : '',
    ms = milliseconds > 0 ? `${Math.round(milliseconds * 100) / 100}ms` : ''

  return `${parts?.h ? h : ''}${parts?.m ? m : ''}${parts?.s ? s : ''}${parts?.ms ? ms : ''}`.trim()
}

export {between, formatBetween}
