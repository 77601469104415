import type { Article } from "@/models/Article";
import { AbstractRepository } from "@/repositories/abstract-repository";
import TranslationRepository from "@/repositories/translation-repository";
import useUserStore from "@/stores/user";

export default class ArticleRepository extends AbstractRepository<Article> {
  static readonly databaseName = 'articles';
  readonly searchFieldIndex = 'searchFieldIndex';
  readonly customerGroupsIndex = 'customerGroupsIndex';
  readonly searchFieldAndCustomerGroupsIndex = 'searchFieldAndCustomerGroupsIndex';

  constructor() {
    super(ArticleRepository.databaseName);
  }

  async createIndex() {
    this.database.createIndex({
      index: {
        fields: [
          'searchField',
        ],
        ddoc: this.searchFieldIndex,
      }
    })

    this.database.createIndex({
      index: {
        fields: [
          'customerGroups',
        ],
        ddoc: this.customerGroupsIndex,
      }
    })

    this.database.createIndex({
      index: {
        fields: [
          'searchField',
          'customerGroups',
        ],
        ddoc: this.searchFieldAndCustomerGroupsIndex,
      }
    })
  }

  async search(searchTerm: string, customerGroup: string|null, articles: Article[]): Promise<Article[]> {
    try {
      if (!customerGroup) {
        return [];
      }

      if (searchTerm.length === 0) {
        return this.findArticles(customerGroup);
      }
      return this.findArticlesBySearchTerm(searchTerm, customerGroup, articles);
    } catch (error) {
      console.error('Error finding all ' + this.database.name + ':', error);
      return Promise.resolve([]);
    }
  }

  async findAllWithImages(): Promise<string[]> {
    return this.database.find({
      selector: {
        thumbnail: {
          $ne: ''
        }
      }
    }).then((result) => result.docs.map((doc: Article) => doc.thumbnail))
      .catch((error) => {
        console.error('Error finding all ' + this.database.name + ':', error);
        return Promise.resolve([]);
      });
  }

  async findArticles(customerGroup: string|null): Promise<Article[]> {
    return this.database.find({
      selector: {
        customerGroups: {
          $elemMatch: {
            $eq: customerGroup,
          }
        }
      },
    }).then((result) => result.docs);
  }

  async findArticlesBySearchTerm(searchTerm: string, customerGroup: string|null, articles: Article[]): Promise<Article[]> {
    const translationRepository = new TranslationRepository();
    const userStore = useUserStore();
    searchTerm = this.addRegexToSearchTerm(searchTerm);

    const productNumbers = await translationRepository.search(searchTerm, userStore.locale)
      .then((result) => result.map((translation) => translation.productNumber).join('|'));

    if (productNumbers.length > 0) {
      searchTerm = `${searchTerm}|${productNumbers}`;
    }

    return articles.filter((article) => {
      return article.searchField.toLowerCase().match(searchTerm.toLowerCase());
    });
  }

  addRegexToSearchTerm(searchTerm: string): string {
    return searchTerm.replace(/\*/gi, ".*");
  }
}
