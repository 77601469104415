<script lang="ts" setup>
import IconTrash from "@/components/icons/IconTrash.vue";
import ArticleImageComponent from "@/components/article/ArticleImageComponent.vue";
import FormInput from "@/components/form/FormInput.vue";
import type { Order } from "@/models/Order";
import { computed, ref, watch } from "vue";
import type { OrderItem } from "@/models/OrderItem";
import CartCalculator from "@/services/helper/cart-calculator";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import PriceTierResolver from "@/services/helper/price-tier-resolver";
import TooltipPriceTierComponent from "@/components/utils/TooltipPriceTierComponent.vue";

const props = defineProps({
  order: {
    type: Object as () => Order,
    required: true,
  },
  isCart: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits<{
  (event: 'update:order', order: Order): void,
  (event: 'remove-order-item', item: OrderItem): void
}>()

const numbers = computed(() => {
  return Array.from({length: 1000}, (_, index) => index + 1);
});
const localOrder = ref(props.order);
const defineShippingAddress = ref(false);

if (localOrder.value.street || localOrder.value.city || localOrder.value.zip) {
  defineShippingAddress.value = true;
}

watch(localOrder, (newVal) => {
  emit('update:order', newVal);
}, {deep: true});

function getSum(quantity: number, price: number) {
  return quantity * price;
}

function sumTotal(): number {
  return CartCalculator.calculateTotal(localOrder.value.items);
}

function updatePrice(item: OrderItem) {
  if (!item.prices) {
    return;
  }
  item.price = PriceTierResolver.resolvePrice(item.prices, item.quantity);
}
</script>

<template>
  <div class="mt-4 p-4 border border-black border-solid rounded">
    <table class="w-full">
      <thead class="text-right">
      <tr>
        <th class="text-left" colspan="2">{{ $t("cart.article") }}</th>
        <th>{{ $t("cart.quantity") }}</th>
        <th>{{ $t("cart.price") }}</th>
        <th>{{ $t("cart.item_total") }}</th>
        <th class=" w-[2.5vw]"></th>
      </tr>
      </thead>
      <tbody class="text-right">
      <tr v-for="(item, index ) in localOrder?.items" :key="index" class="border-t border-solid border-black " :data-qa="item.articleNumber">
        <td class="py-3 w-28">
          <ArticleImageComponent :alt="item.articleNumber" :image-url="item.thumbnail"></ArticleImageComponent>
        </td>
        <td class="py-3 px-4 text-left">
          <span class="font-bold">{{ item.articleName }}</span>
          <p class="text-sm mt-1.5">Art. Nr.: {{ item.articleNumber }}</p>
        </td>
        <td class="py-3 w-[12.5vw]">
          <select v-if="isCart" v-model="item.quantity" class="w-16 p-2 text-white" @change="updatePrice(item)" data-qa="quantity">
            <option v-for="number in numbers" :key="number" :value="number">{{ number }}</option>
          </select>
          <span v-else>{{ item.quantity }}</span>
        </td>
        <td class="py-3 w-[12.5vw]" data-qa="price">
          {{ $n(item.price, 'currency') }}
          <TooltipPriceTierComponent v-if="item.prices" :prices="item.prices"></TooltipPriceTierComponent>
        </td>
        <td class="py-3 w-[12.5vw]">{{ $n(getSum(item.quantity, item.price), 'currency') }}</td>
        <td class="py-3 w-[2.5vw]">
          <span v-if="isCart" :data-trash-button='index' class="cursor-pointer hover:text-primary"
                @click="$emit('remove-order-item', item)">
            <IconTrash></IconTrash>
          </span>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="bg-gray-300 p-4 mx-[-1rem] mb-[-1rem] border-t border-solid border-black">
      <div class="flex flex-wrap">
        <div class="basis-1/2 max-w-[50%] pr-4">
          <div v-if="isCart">
            <div>
              <label for="customerReference">{{ $t("cart.customer_reference") }}</label>
              <FormInput data-qa="form-additional-info" v-model:value="localOrder.additionalInfo" :maxlength=20 name="customerReference" type="text"/>
            </div>
            <div class="mt-5">
              <FormCheckbox data-qa="shipping-address-checkbox" v-model:checked="defineShippingAddress" name="defineShippingAddress" :label='$t("cart.different_shipping_address")' :required="true"/>

              <div v-if="defineShippingAddress" class="mt-3">
                <FormInput data-qa="form-company" v-model:value="localOrder.company" name="company" type="text" :placeholder='$t("cart.company")'/>
                <FormInput data-qa="form-street" v-model:value="localOrder.street" name="address" type="text" :placeholder='$t("cart.street")' class="mt-3"/>
                <div class="flex mt-3">
                  <FormInput data-qa="form-zip" v-model:value="localOrder.zip" name="zip" type="text" :placeholder='$t("cart.zip")' class="w-2/5 mr-3"/>
                  <FormInput data-qa="form-city" v-model:value="localOrder.city" name="city" type="text" :placeholder='$t("cart.city")'/>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>{{ $t("cart.customer_reference") }}: {{ localOrder.additionalInfo }}</p>

            <div v-if="defineShippingAddress" class="mt-3">
              <p>{{ $t("cart.company") }}: {{ localOrder.company }}</p>
              <p>{{ $t("cart.street") }}: {{ localOrder.street }}</p>
              <p class="w-2/5 mr-3">{{ $t("cart.zip") }}: {{ localOrder.zip }}</p>
              <p>{{ $t("cart.city") }}: {{ localOrder.city }}</p>
            </div>
          </div>
        </div>
        <div class="basis-1/2 max-w-[50%]">
          <div class="flex justify-between">
            <strong>{{ $t("cart.total") }}</strong>
            <span class="pr-[2.5vw] cart-total">{{ $n(sumTotal(), 'currency') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
select {
  background: #666 url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAQAAABOiyVOAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfhCQQRKg4Gib/JAAAAWUlEQVQ4y+3RQQoAIAgEQDf6/5e3Q0RSZobXPJUxbChIgTxWeQUfZVE9vlDE3CBvSbQ7ZwSD9Ru8pJVxdP3vacbZuU1vMEUiI8d+iuwJC3b2ZKeFk7b6KIMaKF8ONek6JD0AAAAASUVORK5CYII=) no-repeat center right .5rem;
  appearance: none;
}
</style>
