<template>
  <div class="relative inline-block group z-50" data-qa="tooltip-trigger">
    <div class="absolute w-max top-4 bg-white z-1 p-2 mt-1 text-xs rounded-lg shadow-lg invisible group-hover:visible" data-qa="tooltip-content">
      <slot name="content"></slot>
    </div>
    <span class="text-grey-light" data-qa="tooltip-default">
      <slot></slot>
    </span>
  </div>
</template>
