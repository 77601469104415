import HttpClientSingleton from "@/services/factory/http-client-singleton";
import type { ApiErrorData, ExportResponseData, OrderCreateData } from "@/services/api/resources/OrderData";

const baseApiUri = '/api/PwaOrders/',
  getEndpointUri = (endpoint: string) => baseApiUri + endpoint;


export default class OrderAPI {
  static create(orderData: OrderCreateData): Promise<ExportResponseData> {
    return HttpClientSingleton.getInstance()
      .post(getEndpointUri('create'), {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(orderData)
      })
      .then((responseData: ExportResponseData | ApiErrorData) => {
        if (!responseData.success) {
          throw "message" in responseData ? responseData.message : 'API Error';
        }

        return (responseData as ExportResponseData);
      });
  }
}
