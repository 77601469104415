<script lang="ts" setup>
import useOrderStore from "@/stores/order";
import type { OrderItem } from "@/models/OrderItem";
import { ref, toRaw, watch } from "vue";
import IconArrow from "@/components/icons/IconArrow.vue";
import SecondaryButtonComponent from "@/components/buttons/SecondaryButtonComponent.vue";
import { ORDER_STATUS } from "@/models/Order";
import { OrderExport } from "@/services/export/order-export";
import CartListComponent from "@/components/article/CartListComponent.vue";
import router from "@/router";
import LoadingComponent from "@/components/LoadingComponent.vue";

const loading = ref(false);
const orderStore = useOrderStore();
const {order} = orderStore;
const orderItems: OrderItem[] = order?.items ?? [];

watch(orderItems, () => {
  orderStore.updateOrder();
});

async function removeOrderItem(item: OrderItem) {
  await orderStore.removeOrderItem(item);
}

async function exportOrder() {
  loading.value = true;
  if (order === null) {
    return;
  }

  if (!navigator.onLine) {
    await orderStore.updateOrderStatus(ORDER_STATUS.PENDING);
    orderStore.$reset();
    await router.push({name: 'home'});
    return;
  }

  const orderExport = new OrderExport();
  orderExport.export(toRaw(order)).finally(() => {
    orderStore.$reset();
    router.push({name: 'home'});
  })
}
</script>

<template>
  <div v-if="order != null && orderItems.length > 0" class="container mx-auto mt-10">
    <LoadingComponent :loading="loading">
      <div class="flex justify-between">
        <RouterLink to="articles">
          <SecondaryButtonComponent ref="button_to_articles"
                                    class="flex space-x-4 mx-0 button-to-articles whitespace-nowrap">
            <IconArrow class="fill-current w-4 h-4" orientation="left"></IconArrow>
            {{ $t("cart.button_to_articles") }}
          </SecondaryButtonComponent>
        </RouterLink>
        <SecondaryButtonComponent ref="button_finish_order"
                                  class="flex space-x-4 mx-0 button-finish-order whitespace-nowrap"
                                  @click="exportOrder">
          {{ $t("cart.button_finish_order") }}
          <IconArrow class="fill-current w-4 h-4"></IconArrow>
        </SecondaryButtonComponent>
      </div>
      <CartListComponent v-model:order="order" @remove-order-item="removeOrderItem"></CartListComponent>
      <div class="flex justify-between mt-4">
        <RouterLink to="articles">
          <SecondaryButtonComponent ref="button_to_articles"
                                    class="flex space-x-4 mx-0 button-to-articles whitespace-nowrap">
            <IconArrow class="fill-current w-4 h-4" orientation="left"></IconArrow>
            {{ $t("cart.button_to_articles") }}
          </SecondaryButtonComponent>
        </RouterLink>
        <SecondaryButtonComponent ref="button_print" class="flex space-x-4 mx-0 whitespace-nowrap"
                                  onclick="window.print()">
          {{ $t("cart.button_print") }}
        </SecondaryButtonComponent>
        <SecondaryButtonComponent ref="button_finish_order"
                                  class="flex space-x-4 mx-0 button-finish-order whitespace-nowrap"
                                  @click="exportOrder">
          {{ $t("cart.button_finish_order") }}
          <IconArrow class="fill-current w-4 h-4"></IconArrow>
        </SecondaryButtonComponent>
      </div>
    </LoadingComponent>
  </div>

  <div v-else>
    {{ $t("cart.empty") }}
  </div>
</template>
