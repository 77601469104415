<script lang="ts" setup>

import IconCart from "@/components/icons/IconCart.vue";
import { ref, watch } from "vue";

const amount = ref('' as number | string);

const emit = defineEmits<{
  (event: 'amountChoosen', amount: number): void
  (event: 'updatePrice', amount: number): void
}>();

const increment = () => {
  if (typeof amount.value === "string") {
    amount.value = 0;
  }

  amount.value++;
};

const decrement = () => {
  if (typeof amount.value === "string" || amount.value < 1) {
    return;
  }

  amount.value--;
};

watch(amount, (newValue) => {
  if (typeof newValue === "string" && newValue !== "") {
    newValue = parseInt(newValue);
    amount.value = isNaN(newValue) ? 0 : newValue;
  }
  emit('updatePrice', amount.value as number);
});

function emitAmountChoosen () {
  let amountChoosen = amount.value;
  if (typeof amountChoosen !== "number") {
    return;
  } else if (isNaN(amountChoosen as number)) {
    return;
  } else if (amountChoosen < 1) {
    return;
  }

  emit('amountChoosen', amount.value as number);
  amount.value = '';
}

</script>

<template>
  <div class="flex items-center basket-form">
    <div class="flex flex-col w-20">
      <button
        class="font-bold text-xs py-1 px-4 uppercase ml-0 bg-grey-light text-white border-grey-light hover:bg-primary hover:text-white"
        data-qa="increment"
        @click="increment"
      >
        +
      </button>
      <input class="w-100 border py-1 outline-none text-center focus:border-l-grey-light focus:border-r-grey-light"
             data-qa="amount-input"
             v-model="amount" type="text" min="1" placeholder="Anzahl">
      <button
        class="font-bold text-xs py-1 px-4 uppercase ml-0 bg-grey-light text-white border-grey-light hover:bg-primary hover:text-white"
        data-qa="decrement"
        @click="decrement"
      >
        -
      </button>
    </div>

    <button class="font-bold text-s py-1 px-2 uppercase ml-0 text-grey-light h-[34px] hover:text-primary basket-button" @click="emitAmountChoosen">
      <IconCart></IconCart>
    </button>
  </div>
</template>
