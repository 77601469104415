<script lang="ts" setup>
import IconCart from "@/components/icons/IconCart.vue";
import useOrderStore from "@/stores/order";
import { computed, ref, watch } from "vue";
import { RouterLink } from "vue-router";

const order = useOrderStore();
const animation = ref(false);
const cartItemCount = computed(() => {
  if (order.order === null) {
    return 0;
  }

  return order.order.items.length;
});

watch(cartItemCount, () => {
  animation.value = true;
  setTimeout(() => {
    animation.value = false;
  }, 300);
});

</script>

<template>
  <RouterLink to="/cart">
    <div class="relative">
      <IconCart data-qa="cart-icon"></IconCart>
      <div v-if="cartItemCount > 0"
           id="cart-item-count"
           :class="animation ? 'animate-jiggle' : ''"
           class="absolute -right-2.5 -top-2.5 text-xs bg-grey-light h-5 w-5 pt-0.5 rounded-full text-center">
        {{ cartItemCount }}
      </div>
    </div>
  </RouterLink>
</template>
