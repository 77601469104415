import { ArticleImport } from "@/services/import/article-import";
import { CustomerImport } from "@/services/import/customer-import";
import notifications from "@/services/notification";
import i18n from "@/locales/i18n"
import type { notificationOptions } from "@/types/notification";
import { computed, watch } from "vue";
import { PriceImport } from "@/services/import/price-import";
import { UserPriceImport } from "@/services/import/userPrice-import";
import type { ImportInterface } from "@/services/import/import-interface";
import { formatBetween } from "@/utils/time";
import { ImageImport } from "@/services/import/image-import";
import { TranslationImport } from "@/services/import/translation-import";
import useImportStore from "@/stores/import";

export class ImportHandler {
  private importStore = useImportStore();

  async importAll(): Promise<void> {
    this.importStore.importDataIsRunning = true;
    this.importStore.importImagesIsRunning = true;

    await this.importAllRelevantData();
    await this.importAllImages();

    this.importStore.importDataIsRunning = false;
    this.importStore.importImagesIsRunning = false;
  }

  async importAllRelevantData(): Promise<void> {
    const imports = [
      new ArticleImport(),
      new CustomerImport(),
      new PriceImport(),
      new UserPriceImport(),
      new TranslationImport()
    ];

    this.importStore.importDataIsRunning = true;

    await this.#import(imports).finally(() => {
      this.importStore.importDataIsRunning = false;
      this.importStore.lastDataSync = new Date().toLocaleString();
    });
  }

  async importAllImages(): Promise<void> {
    const imports = [new ImageImport()];

    this.importStore.importImagesIsRunning = true;

    await this.#import(imports).finally(() => {
      this.importStore.importImagesIsRunning = false;
    });
  }

  async #import(imports: ImportInterface[]): Promise<void> {
    const start = Date.now(),
      importPromise = Promise.all(imports.map(i => i.import())),
      progressPercentage = computed(() => {
        const percentage = imports
          .map(i => i.getProgress() === null ? 0 : (i.getProgress()?.percentage.value ?? 0))
          .reduce((a, b) => a + b)
        return percentage ? percentage / imports.length : 0
      }),
      progressTotal = computed(() => imports
        .map(i => (i.getProgress()?.total.value ?? 0))
        .reduce((a, b) => a + b)
      ),
      progressProcessed = computed(() => imports
        .map(i => (i.getProgress()?.processed.value ?? 0))
        .reduce((a, b) => a + b)
      ),
      timeEstimated = computed(() => imports
        .filter(i => (i.getProgress()?.percentage.value ?? 0) < 100)
        .map(i => i.getProgress() === null || (i.getProgress()?.processed.value ?? 0) === 0 ? 0 :
          (Date.now() - start) / (i.getProgress()?.processed.value ?? 0) * (i.getProgress()?.total.value ?? 0)
        )
        .reduce((a, b) => Math.max(a, b), 0)),
      notification = notifications.add(i18n.global.t("notification.import_data.title"), ({
        type: "info",
        timeout: importPromise,
        progress: progressPercentage
      } as notificationOptions));

    const elapsedLabel = i18n.global.t('notification.import_data.elapsed_time'),
      estimatedLabel = i18n.global.t('notification.import_data.estimated_time');

    watch(computed(() =>
      `${progressProcessed.value.toLocaleString('de-DE')} / ${progressTotal.value.toLocaleString('de-DE')}
      <br> ${elapsedLabel}: ${formatBetween(start, Date.now())} <br> ${estimatedLabel}: ${formatBetween(0, timeEstimated.value)}`
    ), (value) => {
      notification.message.value = value
    });

    importPromise.then(() => {
      notifications.add(
        i18n.global.t("notification.update_data_done.title"),
        ({
          type: "success"
        } as notificationOptions))
    }).catch((error) => {
      console.error('Error during import', error);
      notification.kill()
      notifications.add(
        i18n.global.t("notification.update_data_error.title"),
        ({
          type: "error"
        } as notificationOptions))
    });

    await importPromise;
  }
}
