<script lang="ts" setup>
import PaginationComponent from "@/components/PaginationComponent.vue";
import { computed, ref } from "vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconOpen from "@/components/icons/IconOpen.vue";

interface Formatters {
  [key: string]: string;
}

interface Properties {
  mapping: string[];
  data: InstanceType<any>[];
  actions?: TableAction[];
  translationPrefix?: string;
  formatters: Formatters;
}

defineEmits<{
  (event: 'row-clicked', row: InstanceType<any>): void
  (event: 'action-triggered', row: any, action: string): void
}>();

const props = withDefaults(defineProps<Properties>(), {
  actions: () => [],
  formatters: () => ({}),
});

const pageSize = ref(10);
const currentPage = ref(1);

const body = computed(() => props.data.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value));

const numItems = computed(() => props.data.length);

function applyMapping(row: InstanceType<any>) {
  return props.mapping.map((key: string) => key in row ? Reflect.get(row, key) : null)
}

function getIcon(action: string) {
  switch (action) {
    case Action.edit:
      return IconEdit;
    case Action.delete:
      return IconTrash;
    case Action.create:
      return IconPlus;
    case Action.open:
      return IconOpen;
  }
  throw "undefined action"
}

</script>

<script lang="ts">
type ActionCondition = (row: any) => boolean;

export interface TableAction {
  condition?: ActionCondition;
  action: Action;
}

export enum Action {
  edit = 'edit',
  delete = 'trash',
  create = 'plus',
  open = 'arrow-up-right-from-square',
}
</script>

<template>
  <div>
    <table class="w-full" :class="{ 'h-[33rem]' : body.length === 0}">
      <thead class="bg-grey-light text-white">
      <tr class="divide-x-2 divide-white">
        <th v-for="key in mapping" :key="key" class="px-3 py-3 uppercase">
          {{ $t(translationPrefix + '.' + key) }}
        </th>
        <th v-if="actions" class="px-2 py-2 uppercase">
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row,index) in body" :key="index"
          class="hover:bg-primary hover:text-white hover:cursor-pointer divide-x-2 divide-white odd:bg-grey-lighter even:bg-grey-very-light text-grey-light text-center"
          @click="$emit('row-clicked', row)"
          :data-qa="index">
        <td v-for="(val,index) in applyMapping(row)" :key="index" :data-qa="val" class="px-3 py-3">
          <template v-if="val === '' || val === null">
            -
          </template>
          <template v-if="val">
            <span v-if="formatters[index] == 'currency'">{{ $n(val, 'currency') }}</span>
            <span v-else-if="formatters[index] == 'date'">{{ $d(val, 'long') }}</span>
            <span v-else>{{ val }}</span>
          </template>
        </td>
        <td v-if="actions" data-qa="actions">
            <span v-for="action in actions" :key="action.action"
                  :data-cy-action="action.action"
                  @click="$event.stopPropagation();$emit('action-triggered', row, action.action)">
              <Component :is="getIcon(action.action)"
                         v-if="action.condition === undefined || (action.condition as ActionCondition)(row)"
                         class="px-1"></Component>
            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <PaginationComponent v-if="body.length > 0 && data.length > pageSize" :numItems="numItems"
                       @pageChange="(newPage: number) => currentPage = newPage"
                       :page-size="pageSize"></PaginationComponent>
</template>
