<script lang="ts" setup>
interface Properties {
  orientation?: 'right' | 'down' | 'left' | 'up'
}

defineProps<Properties>()
const getRotation = (orientation?: string) => 'origin-center ' + ['rotate-0', 'rotate-90', 'rotate-180', 'rotate-270'][['right', 'down', 'left', 'up'].indexOf(orientation ?? 'right')]
</script>
<template>
  <svg class="max-h-full max-w-full m-auto" fill="currentColor" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path :class="getRotation(orientation)"
          d="m19.74 14.004-9.354 9.353-.707-.707 8.646-8.646L9.68 5.357l.707-.707Z"></path>
  </svg>
</template>
