<script lang="ts" setup>
import CartComponent from "@/components/header/CartComponent.vue";
import IconUser from "@/components/icons/IconAccount.vue";
import useUserStore from "@/stores/user";
import { RouterLink } from "vue-router";
import LanguageComponent from "@/components/LanguageComponent.vue";

const store = useUserStore();

function logout() {
  store.logout();
  window.location.reload();
}

function openSidebarCart() {
  // not yet implemented
}

function onAccount() {
  // not yet implemented
}

</script>

<template>
  <header class="bg-grey text-white">
    <div class="w-full flex justify-between h-20 container mx-auto">
      <RouterLink class="h-12 my-auto" to="/">
        <img alt="CosmicSports" class="h-12" src="@/assets/logo-cosmicsport-horizontal-white.webp"/>
      </RouterLink>

      <nav class="my-auto gap-4 grid-flow-col grid font-bold uppercase text-sm text-center items-center">
        <RouterLink to="/customers">{{ $t("header.create_order") }}</RouterLink>
        <RouterLink to="/articles">{{ $t("header.articles") }}</RouterLink>
        <a @click="logout">{{ $t("header.logout") }}</a>
        <div class="h-full border-solid border-current border-r"></div>
        <a class="text-lg" @click="openSidebarCart">
          <CartComponent></CartComponent>
        </a>
        <div class="h-full border-solid border-current border-r"></div>
        <LanguageComponent/>
        <div class="h-full border-solid border-current border-r"></div>
        <a class="text-lg" @click="onAccount">
          <icon-user></icon-user>
        </a>
      </nav>
    </div>
  </header>
</template>

<style scoped>
nav a {
  @apply my-auto cursor-pointer;
}
</style>
