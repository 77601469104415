import type {Ref} from "vue"
import {computed, ref } from "vue"
import type {ProgressInterface} from "@/stores/progress-interface"

const progressStore: Progress[] = []

class Progress implements ProgressInterface {
  name: string;
  total: Ref<number>
  #total = ref(0)
  processed: Ref<number>;
  #processed = ref(0)
  #percentage: Ref<number>;

  constructor(name: string, total?: number) {
    this.name = name
    this.#total.value = total ?? 0
    this.total = computed({
      get: () => this.#total.value,
      set: (value) => {
        if (this.processed.value > value) {
          console.error(`Progress ${this.name} is over 100%`)
          this.#processed.value = value
        }
        return this.#total.value = value
      }
    })
    this.processed = computed({
      get: () => this.#processed.value,
      set: (value) => {
        if (value > this.total.value) {
          console.error(`Progress ${this.name} is over 100%`)
          return this.#processed.value = this.#total.value
        }
        return this.#processed.value = value
      }
    })
    this.#percentage = computed(() =>
      this.total.value > 0 && this.processed.value > 0 ?
        Math.min((100 / this.total.value * this.processed.value), 100) : 0)
  }

  finish() {
    this.processed.value = this.total.value
    return this
  }

  close() {
    progressStore.splice(progressStore.indexOf(this), 1)
  }

  updateTotal(total: number) {
    this.total.value = total
    return this
  }

  addProgress(progress: number) {
    this.processed.value += progress
    return this
  }

  get percentage() {
    return this.#percentage
  }
}

export default {
  initProgress(name: string, total?: number) {
    const progress = new Progress(name, total)
    progressStore.push(progress)
    return progress
  },
  getProgress(name: string) {
    return progressStore.find(p => p.name === name) ?? null
  }
}
