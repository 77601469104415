import { createI18n } from "vue-i18n";
import de_DE from "@/locales/de_DE.json";
import en_GB from "@/locales/en_GB.json";

export default createI18n({
  legacy: false,
  fallbackLocale: "en-GB",
  availableLocales: ["de-DE", "en-GB"],
  messages: {
    'de-DE': de_DE,
    'en-GB': en_GB
  },
  datetimeFormats: {
    'de-DE': {
      long: {
        year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric"
      }
    },
    'en-GB': {
      long: {
        year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric"
      }
    }
  },
  numberFormats: {
    'de-DE': {
      currency: {
        style: "currency", currency: "EUR"
      }
    },
    'en-GB': {
      currency: {
        style: "currency", currency: "EUR"
      },
      decimal: {
        style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
      }
    }
  }
});
